import Vue from 'vue'
import App from './App.vue'
// 引入ELEMENT
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

// 易通Web组态
import econdraw from "econdraw";
import 'econdraw/dist/index.css'   // 放在矢量图片后引入，导致矢量图显示错误
Vue.use(econdraw);

// 阿里矢量图
import './assets/iconfont/iconfont.css'
import './assets/iconfontSwitch/iconfont.css'

import './assets/manage.css'

// 引入FILTER
import filters from '../src/utils/filter'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})




// 引入全局配置
import config from '../src/utils/config'
Vue.prototype.$config = config.data

Vue.config.productionTip = false

import router from './router'
import store from './store'
router.beforeEach((to, from, next) => {
  document.title = (to.meta.title ? to.meta.title + '-' : '') + '结冰监测预警及自动消冰系统'
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')